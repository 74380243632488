import React, {useState} from "react";
import Layout from "../components/layout2";


const PrivacyPage = () => {

  return (
    <Layout>
      <div className="flex justify-center items-center px-8 mt-40">
        <section className="max-w-3xl">
        <h1 className="font-display text-display-md font-bold mb-4">Privacy Policy</h1>
          <p className="font-body text-body-lg">Last updated: 11/21/2024</p>
          <p className="font-body text-body-lg mb-4">
            This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </p>
          <p className="font-body text-body-lg mb-4">
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Interpretation and Definitions</h2>
          <h3 className="font-display text-display-xs font-medium mb-4">Interpretation</h3>
          <p className="font-body text-body-lg mb-4">
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4 mt-6">Definitions</h3>
          <p className="font-body text-body-lg mb-4">
            For the purposes of this Privacy Policy:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
            <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
            <li><strong>Company:</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Legion AZ, 1056 W Corriente Dr, San Tan Valley, AZ 85143.</li>
            <li><strong>Cookies:</strong> Small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
            <li><strong>Country:</strong> Refers to Connecticut, United States.</li>
            <li><strong>Device:</strong> Any device that can access the Service, such as a computer, a cellphone, or a digital tablet.</li>
            <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
            <li><strong>Service:</strong> Refers to the Website.</li>
            <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.</li>
            <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li><strong>The Website:</strong> Refers to Legion AZ and Squarespace.</li>
            <li><strong>You:</strong> The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          </ul>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Collecting and Using Your Personal Data</h2>
          <h3 className="font-display text-display-xs font-medium mb-4 mt-4">Types of Data Collected</h3>
          <h4 className="font-display text-display-xs font-medium mb-4 mt-4">Personal Data</h4>
          <p className="font-body text-body-lg mb-4">
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
          </ul>

          <h4 className="font-display text-display-xs font-medium mb-4 mt-4">Usage Data</h4>
          <p className="font-body text-body-lg mb-4">
            Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
          </p>
          <p className="font-body text-body-lg mb-4">
            When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers, and other diagnostic data.
          </p>
          <p className="font-body text-body-lg mb-4">
            We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
          </p>
          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Tracking Technologies and Cookies</h2>
          <p className="font-body text-body-lg mb-4">
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4 mt-4">Cookies or Browser Cookies</h3>
          <p className="font-body text-body-lg mb-4">
            A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4 mt-4">Web Beacons</h3>
          <p className="font-body text-body-lg mb-4">
            Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4 mt-4">Types of Cookies</h3>
          <p className="font-body text-body-lg mb-4">
            Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the Free Privacy Policy website article.
          </p>
          <p className="font-body text-body-lg mb-4">
            We use both Session and Persistent Cookies for the purposes set out below:
          </p>

          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>
              <strong>Necessary / Essential Cookies</strong><br />
              <strong>Type:</strong> Session Cookies<br />
              <strong>Administered by:</strong> Us<br />
              <strong>Purpose:</strong> These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
            </li>
            <li className="mt-4">
              <strong>Cookies Policy / Notice Acceptance Cookies</strong><br />
              <strong>Type:</strong> Persistent Cookies<br />
              <strong>Administered by:</strong> Us<br />
              <strong>Purpose:</strong> These Cookies identify if users have accepted the use of cookies on the Website.
            </li>
            <li className="mt-4">
              <strong>Functionality Cookies</strong><br />
              <strong>Type:</strong> Persistent Cookies<br />
              <strong>Administered by:</strong> Us<br />
              <strong>Purpose:</strong> These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
            </li>
          </ul>

          <p className="font-body text-body-lg mb-4">
            For more information about the cookies we use and your choices regarding cookies, please visit our <a href="/cookies-policy" className="text-blue-600 hover:underline">Cookies Policy</a> or the Cookies section of our Privacy Policy.
          </p>
          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Use of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>
              <strong>To provide and maintain our Service:</strong> Including to monitor the usage of our Service.
            </li>
            <li className="mt-4">
              <strong>To manage Your Account:</strong> To manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
            </li>
            <li className="mt-4">
              <strong>For the performance of a contract:</strong> The development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.
            </li>
            <li className="mt-4">
              <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
            </li>
            <li className="mt-4">
              <strong>To provide You with news, special offers, and general information:</strong> About other goods, services, and events we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
            </li>
            <li className="mt-4">
              <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </li>
            <li className="mt-4">
              <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
            </li>
            <li className="mt-4">
              <strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and Your experience.
            </li>
          </ul>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Retention of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
          </p>
          <p className="font-body text-body-lg mb-4">
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or when we are legally obligated to retain this data for longer time periods.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Transfer of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. This means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.
          </p>
          <p className="font-body text-body-lg mb-4">
            Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
          </p>
          <p className="font-body text-body-lg mb-4">
            The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy, and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of Your data and other personal information.
          </p>
          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Use of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>
              <strong>To provide and maintain our Service:</strong> Including to monitor the usage of our Service.
            </li>
            <li className="mt-4">
              <strong>To manage Your Account:</strong> To manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
            </li>
            <li className="mt-4">
              <strong>For the performance of a contract:</strong> The development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.
            </li>
            <li className="mt-4">
              <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
            </li>
            <li className="mt-4">
              <strong>To provide You with news, special offers, and general information:</strong> About other goods, services, and events we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
            </li>
            <li className="mt-4">
              <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </li>
            <li className="mt-4">
              <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
            </li>
            <li className="mt-4">
              <strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and Your experience.
            </li>
          </ul>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Retention of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
          </p>
          <p className="font-body text-body-lg mb-4">
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or when we are legally obligated to retain this data for longer time periods.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Transfer of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. This means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.
          </p>
          <p className="font-body text-body-lg mb-4">
            Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
          </p>
          <p className="font-body text-body-lg mb-4">
            The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy, and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of Your data and other personal information.
          </p>
          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Delete Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
          </p>
          <p className="font-body text-body-lg mb-4">
            Our Service may give You the ability to delete certain information about You from within the Service.
          </p>
          <p className="font-body text-body-lg mb-4">
            You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
          </p>
          <p className="font-body text-body-lg mb-4">
            Please note, however, that we may need to retain certain information when we have a legal obligation or lawful basis to do so.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Disclosure of Your Personal Data</h2>
          <h3 className="font-display text-display-xs font-medium mb-4">Business Transactions</h3>
          <p className="font-body text-body-lg mb-4">
            If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4">Law Enforcement</h3>
          <p className="font-body text-body-lg mb-4">
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
          </p>

          <h3 className="font-display text-display-xs font-medium mb-4">Other Legal Requirements</h3>
          <p className="font-body text-body-lg mb-4">
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Security of Your Personal Data</h2>
          <p className="font-body text-body-lg mb-4">
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Children’s Privacy</h2>
          <p className="font-body text-body-lg mb-4">
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
          </p>
          <p className="font-body text-body-lg mb-4">
            If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Links to Other Websites</h2>
          <p className="font-body text-body-lg mb-4">
            Our Service may contain links to other websites that are not operated by Us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
          </p>
          <p className="font-body text-body-lg mb-4">
            We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or services.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Changes to this Privacy Policy</h2>
          <p className="font-body text-body-lg mb-4">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="font-body text-body-lg mb-4">
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.
          </p>
          <p className="font-body text-body-lg mb-4">
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          </p>

          <h2 className="font-display text-display-sm font-medium mb-4 mt-6">Contact Us</h2>
          <p className="font-body text-body-lg mb-4">
            If you have any questions about this Privacy Policy, You can contact us:
          </p>
          <ul className="list-disc list-inside font-body text-body-lg mb-4">
            <li>
              By email: <a href="mailto:team@trucostroofing.com" className="text-blue-600 hover:underline">team@trucostroofing.com</a>
            </li>
            <li>
              By visiting this page on our website: <a href="https://www.trucostroofing.com/" className="text-blue-600 hover:underline">https://www.legionroofingaz.com/</a>
            </li>
            <li>By phone number: 469-484-7533</li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default PrivacyPage;